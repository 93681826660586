import React from "react"
import Layout from "../../components/modules/Layout"
import ContentNavBlock from "../../components/blocks/ContentNavBlock"

const DemoIndexPage = () => {
  return (
    <Layout>
      <ContentNavBlock
        block={{
          component: "content_nav_block",
          headline: "Übersicht für Komponenten",
          nav_items: [
            {
              title: "Basiskomponenten",
              description: "Basiskomponenten für die Gestaltung von Texten.",
              link: "/dev/basic",
              link_text: "Mehr",
            },
            {
              title: "Mediakomponenten",
              description:
                "Mediakomponenten für die Darstellung von Bildern und Videos",
              link: "/dev/media",
              link_text: "Entdecken",
            },
            {
              title: "Erweiterungskomponenten",
              description:
                "Erweiterungskomponenten für speziellere Anforderungen (Tabs, Akkordeon, Karten usw.)",
              link: "/dev/extended",
            },
            {
              title: "Animationen",
              description: "Beispiele für vorhandene Animations-Effekte",
              link: "/dev/animations",
            },
            {
              title: "Nur Texte",
              description: "Beispiele für Blöcke ohne zusätzlichen Medien",
              link: "/dev/texts-only",
            },
          ],
        }}
      />
    </Layout>
  )
}

export default DemoIndexPage
